.services-wrapper{
  text-align: center;
  display: flex;
  justify-content: space-around;
  color: black;
  padding: 10px
}

.services-container{
  max-width: 800px;
}

.service-title{
  font-size: 32px;
  padding-top: 1.5rem;
}

.service-content-container{
  background-color: rgb(251, 241, 231);
  border-radius: 15px;
  margin: 20px 0 20px 0 ;
  padding: 10px;
}

.service-description{
  text-align: left;
  padding: 2rem;
}

@media (max-width: 750px) {
  .services-wrapper{
    padding: 0;
  }
}