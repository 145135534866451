.about-container{
  display: flex;
  justify-content: space-around;
}

.about-image{
  max-width: 500px;
}

.about-content{
  max-width: 500px;
  color: black;
  text-align: left;
}

.about-bottom-image{
  max-width: 100%;
  margin: 20px;
}

@media (max-width: 750px) {
  .about-container{
  display: block;
  }
}
