.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color: rgb(255, 255, 255);
  color: rgb(227, 147, 132);
  font-family: serif, sans-serif;
  font-size: 20px;
}

.button {
  border-radius: 5px;
  width: 100px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  background-color: rgb(227, 147, 132);
  color: rgb(255, 255, 255);
}

.button:hover{
  opacity: 0.6;
  cursor: pointer;
}
